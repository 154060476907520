import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { UserService } from 'app/core/user/user.service';
import { InternalMessageService } from 'app/layout/layout.service';
import { base_component } from 'app/shared/components/base_component';
import { NotificationService } from 'app/shared/service/notification.service';
import { AudioRecordingService } from '../../service/audio-recording.service';
import { v4 as uuid, validate } from 'uuid';
import { HelperService } from 'app/shared/service/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaService } from '../../service/media.service';
import { map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { EngagementService } from 'app/shared/service/engagement.service';
import { Engagement } from 'app/shared/model/engagement.types';
import { CurrentUser } from 'app/core/auth/user';
import { TprmProjectService } from 'app/shared/service/tprm-project.service';
import { TprmResponseService } from 'app/shared/service/tprmResponse.service';
import { InterviewService } from '../../../modules/request-interview/interview/interview.service';
import { DOCUMENT } from '@angular/common';
import { FileUploaderServiceService } from 'app/shared/service/file-uploader-service.service';
import { ReassignComponent } from 'app/shared/components/reassign/reassign.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseLoadingService } from '@fuse/services/loading';
import { ControlAssignService } from 'app/shared/service/control-assign.service';

@Component({
  selector: 'app-record-interview',
  templateUrl: './record-interview.component.html',
  styleUrls: ['./record-interview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class RecordInterviewComponent extends base_component implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('audioPlayer') audioPlayer: any;
    @Input('question') question: any;
    @Input('questions') questions: any;

    audio: any;
    file: File = null;
    project: Engagement;
    currentStep: number = 1;
    isPlaying = false;
    displayControls = true;
    isAudioRecording = false;
    audioRecordedTime;
    audioBlob;
    audioName;
    audioStream;
    audioConf = { audio: true}
    // doAudioInterview: boolean = true;
    selectedQuestion: any;
    recordedFile: any = null;
    recordingURL: string = '';
    selectQuestionNumber: number = 0;
    guid: string = '';
    projectId: string = '';
    roleId: string = '';
    questionAnswered: any[] = [];
    context: CanvasRenderingContext2D;
    audioBlobUrl: any;
    interviewType: string = 'Written';
    isTPRM: boolean = false;
    interviewLanguage: string = 'english';
    response: string = '';
    quillModules: any = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{align: []}, {list: 'ordered'}, {list: 'bullet'}],
            ['clean']
        ]
    };
    audioStatus: string = '';
    audioStarted: boolean = false;
    // audioDone: boolean = false;
    questionsStatusEnum = {
        notDone: 1,
        done: 2,
        lastQuestion: 3
    }
    questionsStatus: number = 1;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        public _userService: UserService,
        public _notifyService: NotificationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private audioRecordingService: AudioRecordingService,
        private sanitizer: DomSanitizer,
        private _router: Router,
        private _helper: HelperService,
        private _mediaService: MediaService,
        private _internalMessageService: InternalMessageService,
        private _projectService: EngagementService,
        private _tprmProjectService: TprmProjectService,
        private _tprmResponseService: TprmResponseService,
        private _interviewService: InterviewService,
        private _matDialog: MatDialog,
        private _fuseLoadingService: FuseLoadingService,
        private _controlAssignService: ControlAssignService,
        private _fileUploadService: FileUploaderServiceService,
    ) {
        super(_notifyService, _userService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.interviewType = localStorage.getItem('interview') || 'Audio';
        this.interviewLanguage = localStorage.getItem('language') || 'english';

        this.startInterview();
        this.loadAudioControls();
        this.getCurrentUser();

        this.projectId = this._activatedRoute.snapshot.paramMap.get('projectid');
        this.isTPRM = this._router.url.includes('tprm');
        this.roleId = this._activatedRoute.snapshot.paramMap.get('roleid');

        if (this.isTPRM) {
            this.getTPRMProject();
        }
        else {
            this.getProject();
        }
    }

    ngAfterViewInit(): void
    {
        this.getMessages();
    }

    ngOnDestroy() {
        this.abortAudioRecording();
        // Unsubscribe from all subscriptions
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    private _scrollCurrentStepElementIntoView(): void
    {
        // Wrap everything into setTimeout so we can make sure that the 'current-step' class points to correct element
        setTimeout(() => {

            // Get the current step element and scroll it into view
            const currentStepElement = this._document.getElementsByClassName('current-step')[0];
            if ( currentStepElement )
            {
                currentStepElement.scrollIntoView({
                    behavior: 'smooth',
                    block   : 'start'
                });
            }
        });
    }

    goToStep(step: number, action: string): void
    {
       const selectedQuestion = this.questions[step - 1];
       console.log("🚀 ~ RecordInterviewComponent ~ selectedQuestion:", selectedQuestion)
       this._internalMessageService.sendMessage({name:'interview', data: {action: action, question_number: selectedQuestion?.question_number}});
        // Set the current step
        this.currentStep = step;

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    goToPreviousStep(): void
    {
        // Return if we already on the first step
        if ( this.currentStep === 0 )
        {
            return;
        }
        this.goToStep(this.currentStep - 1, 'prev');

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    goToNextStep(): void
    {
        // Return if we already on the last step
        if ( this.currentStep === this.questions.length)
        {
            return;
        }
        this.goToStep(this.currentStep + 1, 'next');

        // Scroll the current step selector from sidenav into view
        this._scrollCurrentStepElementIntoView();
    }

    startInterview(): void {
        // this.doAudioInterview = true;
        this.recordedFile = null;
        this.clearAudioRecordedData();

        this._changeDetectorRef.detectChanges();
    }

    startAudioRecording() {
        this.audioStatus = 'start';
        this.audioStarted = true;
        if (this.audioPlayer?.nativeElement) {
            this.audio = this.audioPlayer?.nativeElement;
            this.audio.muted = true;
            this.audio.volume = 0;
            this.audio.setAttribute("hidden", true);
        }
        if (!this.isAudioRecording) {
            this.isAudioRecording = true;
            this.audioRecordingService.startRecording();
        }
        this._changeDetectorRef.detectChanges();
    }

    pauseAudioRecording(): void {
        this.audioStatus = 'pause';
        if (this.isAudioRecording) {
            this.audioRecordingService.pauseRecording();
            this._changeDetectorRef.detectChanges();
        }
    }

    resumeAudioRecording(): void {
        this.audioStatus = 'resume';
        if (this.isAudioRecording) {
            this.audioRecordingService.resumeRecording();
            this._changeDetectorRef.detectChanges();
        }
    }

    abortAudioRecording() {
        this.audioStatus = 'abort';
        if (this.isAudioRecording) {
            this.isAudioRecording = false;
            this.audioRecordingService.abortRecording();
            this._changeDetectorRef.detectChanges();
        }
    }

    stopAudioRecording() {
        this.audioStatus = 'stop';
        this.audio.removeAttribute("hidden");
        if (this.isAudioRecording) {
            // this.setGuid();
            this.audioRecordingService.stopRecording();
            this.isAudioRecording = false;
            if (this.audio) {
                this.audio.muted = false;
                this.audio.volume = 0.8;
            }
        }
    }

    clearAudioRecordedData() {
        this.audioStatus = '';
        this.audioBlobUrl = null;
        if (this.audio) {
            this.audio.srcObject = null;
            this.audio.removeAttribute("hidden");
        }
        this._changeDetectorRef.detectChanges();
    }

    downloadAudioRecordedData() {
        this._downloadFile(this.audioBlob, 'audio/mp3', this.audioName);
    }

    _downloadFile(data: any, type: string, filename: string): any {
        const blob = new Blob([data], { type: type });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = url;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    deleteRecorded(): void {
        this.subs.sink = this._mediaService.delete(this.selectedQuestion.media_guid)
        .subscribe((response:any) => {
            if (response.status === "success" && response.data[0].affectedRows > 0) {
                this._notifyService.showSuccess('', 'Successfully delete the recorded interview.');
                this.selectedQuestion = null;
                this._changeDetectorRef.detectChanges();
            }
            else {
                this._notifyService.showError('', 'Failed to delete the recorded interview.');
            }
        });
    }

    submitWritten(): void {
        const formData:any = {};
        if (this.guid) {
            formData.guid = this.guid;
            formData.updated_by = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
        }
        else {
            formData.guid = uuid();
            formData.updated_by = '';
        }
        formData.response = this.response;
        formData.control_guid = this.selectedQuestion.control_guid;
        formData.project_guid = this.projectId;
        formData.user_id = this.currentUser.id;
        formData.role_id = this.roleId;
        formData.created_by = `${this.currentUser.first_name} ${this.currentUser.last_name}`;

        if (this.isTPRM) {
            this.subs.sink = this._tprmResponseService.createResponse(formData)
            .subscribe((result:any) => {
                if (result?.status === 'success') {
                    this._notifyService.showSuccess('', 'interview response submitted successfully!');
                    this._internalMessageService.sendMessage({name:'interview', data: {action: 'next', question_number: this.selectQuestionNumber + 1}});
                }
                else {
                    this._notifyService.showError('', 'Failed to submit the interview response!');
                }
            });
        }
        else {
            this.subs.sink = this._mediaService.saveWritten(formData)
            .subscribe((response:any) => {
                if (response.status === 'success') {
                    this._notifyService.showSuccess('', 'interview response submitted successfully!');
                    this._internalMessageService.sendMessage({name:'interview', data: {action: 'next', question_number: this.selectQuestionNumber + 1}});
                }
                else {
                    this._notifyService.showError('', 'Failed to upload the file.');
                }
            });
        }
    }

    submitAudio(): void {
        const sq = this.selectedQuestion;
        const guid = uuid();
        const fileName = `${this.currentUser.id}-${sq.role_id || 'no_role'}-${sq.control_id}-${sq.responsibility_id}-question-${this.selectQuestionNumber}`;
        this.file = new File([this.audioBlob], fileName, { type: 'audio/mp3' });
        const extArray = this.file.type.split("/");
        const extension = extArray[extArray.length - 1];
        const key = `${this.currentUser.db_name}/projects/${this.projectId}/interview/${guid}_${fileName}.${extension}`;
        this.uploadFile(this.file, fileName, key);
    }

    //1. get presigned url
    private uploadFile(file: File, fileName: string, key: string) {
        this._fuseLoadingService.show();

        this._fileUploadService.uploadToS3(file, key)
        .then((response:any) => {
            this.uploadFileData(fileName, file.type, key);
        }).catch((response:any) => {
            this._fuseLoadingService.hide();
            this._notifyService.showError('', 'Failed to upload the file.');
        })
    }

    //2. upload data for processing
    private uploadFileData(fileName:string, fileType:string, key:string) {
        try {
            const hasTPRMinRoute = this._router.url.includes('tprm') ? 1 : 0;
            const guid = this.guid ? this.guid : uuid();
            const iso = this._helper.getISO(this.interviewLanguage);
            const data =  {
                guid: guid,
                iso: iso,
                name: fileName,
                tprm: hasTPRMinRoute,
                type: this.file.type,
                role_id: this.roleId,
                size: this.file.size,
                user_id: this.currentUser.id,
                project_guid: this.projectId,
                responsibility_id: this.selectedQuestion.responsibility_id,
                responsibilitygroup_id: this.selectedQuestion.responsibilitygroup_id,
                standard_id: this.selectedQuestion.standard_id,
                question_number: this.selectedQuestion.question_number,
                control_id: this.selectedQuestion.control_id,
                control_guid: this.selectedQuestion.control_guid,
                created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                whisper: '1',
                campaign:'',
                interaction_id: '1',
                fileName: fileName,
                contentType: fileType,
                key: key
            }

            this.subs.sink = this._mediaService.uploadFileData(data)
            .subscribe((response:any) => {
                if (response && response.status === 'success') {
                    this._notifyService.showSuccess('', 'Successfully uploaded the file.');
                    this.file = null;

                    const mediaAnswered = response.data[0][0]?.mediaCount;
                    // console.log('2 done?????????????????', this.questions.length, mediaAnswered,this.selectQuestionNumber, this.questionsStatus, this.selectedQuestion);
                    if (mediaAnswered === this.questions.length) {
                        this.questionsStatus = this.questionsStatusEnum.done;
                        // console.log('3 done?????????????????', this.questions.length, mediaAnswered, this.selectQuestionNumber, this.questionsStatus);
                    }
                    else if (this.selectedQuestion.id === this.questions.length) { //question_order_id
                        this.questionsStatus = this.questionsStatusEnum.lastQuestion;
                        // console.log('4 done?????????????????', this.questions.length, mediaAnswered, this.selectQuestionNumber, this.questionsStatus);
                        this.questionsStatus = this.questionsStatusEnum.lastQuestion;
                    }
                    else {
                        this.questionsStatus = this.questionsStatusEnum.notDone;
                        // console.log('5 done?????????????????', this.questions.length, mediaAnswered, this.selectQuestionNumber, this.questionsStatus);
                        const questionData = {
                            action: 'next',
                            selectedQuestionNumber: this.selectedQuestion.question_number,
                            question_number: this.selectQuestionNumber + 1,
                            control_id: this.selectedQuestion.control_id,
                            media_guid: guid
                        };
                        // console.log('6 done?????????????????', questionData);
                        this._internalMessageService.sendMessage(
                            {
                                name:'interview',
                                data: questionData
                            }
                        );
                    }
                }
                else {
                    if (response?.message === 'no-content') {
                        this._notifyService.showError('', 'Answer cannot be empty!.');
                    }
                    else {
                        this._notifyService.showError('', 'Failed to upload the file.');
                    }
                }
                this._fuseLoadingService.hide();
                this._changeDetectorRef.detectChanges();
            });

        } catch (error) {
            this._fuseLoadingService.hide();
            this._changeDetectorRef.detectChanges();
        }
    }

    /**
     * Open the note dialog
     */
    openReassignDialog(control: any): void
    {
        this._matDialog.open(ReassignComponent, {
            autoFocus: true,
            data     : {
                control: cloneDeep(control),
                projectId: this.projectId,
                companyGuid: this.project?.vendor_id
            }
        });

        this._changeDetectorRef.markForCheck();
    }

    deleteDelegatedUser(e: any, controlGuid: any): void {
        this.subs.sink = this._controlAssignService.cancelAssign(this.projectId, controlGuid)
        .pipe(
            // map((val: any) => val?.data[0] || [])
        )
        .subscribe((result: any) => {
            if (result.status === 'success') {
                // this.getControls(this.projectId);
                this._notifyService.showSuccess('', "Delegate user has been deleted!");
                this.selectedQuestion.delegated_email = null;
                this._changeDetectorRef.markForCheck();
            }
            else {
                this._notifyService.showError('', "Failed to delegate user!");
            }
        });
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private downloadMediaFile(guid: string): void {
        this.subs.sink = this._mediaService.downloadFile(guid)
        .pipe(
            map((val: any) => val?.data ? val?.data : '')
        )
        .subscribe((response: any) => {
            this.audioBlobUrl = response;
        });
    }

    private fetchMediaSource(projectId: string, controlId: string): void {
        this.subs.sink =this._mediaService.getMediaByProjectAndControl(projectId, controlId)
        .pipe(
            map((val: any) => val?.data[0]  || [])
        )
        .subscribe((result: any) => {
            if (result && result[0] && result[0].length > 0) {
                const sources = result[0];

                this.downloadMediaFile(sources.guid);
            }
            // else {
            //     this._notifyService.showError('','Requested file cannot be downloaded');
            // }
        });
    }

    private getProject(): void {
        this.subs.sink = this._projectService.getEngagementById(this.projectId)
            .pipe(
                // map((val: any) => val?.data[0] || [])
            )
            .subscribe((project: any) => {
                this.project = project[0];
            });
    }

    private getTPRMProject(): void {
        this.subs.sink = this._tprmProjectService.getEngagementById(this.projectId)
            .pipe(
                // map((val: any) => val?.data[0] || [])
            )
            .subscribe((project: any) => {
                console.log("🚀 ~ RecordInterviewComponent ~ .subscribe ~ project:", project)
                this.project = project[0];
            });
    }

    private loadAudioControls() {
        this.subs.sink = this.audioRecordingService.recordingFailed()
        .subscribe(() => {
            this.isAudioRecording = false;
            this._changeDetectorRef.detectChanges();
        });

        this.subs.sink = this.audioRecordingService.getRecordedTime()
        .subscribe((time) => {
            this.audioRecordedTime = time;
            this._changeDetectorRef.detectChanges();
        });

        this.subs.sink = this.audioRecordingService.getRecordedBlob()
        .subscribe((data) => {
            this.audioBlob = data.blob;
            this.audioName = data.title;
            this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
            this._changeDetectorRef.detectChanges();
        });
    }

    private getWrittenResponse(): void {
        if (this.isTPRM) {
            this.subs.sink =this._tprmResponseService.getResponse(this.projectId, this.selectedQuestion.control_guid)
            .pipe(
                map((val: any) => val?.data[0]  || [])
            )
            .subscribe((result: any) => {
                if (result && result[0]) {
                    this.guid = result[0].guid;
                    this.response = result[0].response;
                }
            });
        }
        else {
            this.subs.sink =this._interviewService.getProjectResponse(this.projectId, this.selectedQuestion.control_guid)
            .pipe(
                map((val: any) => val?.data[0]  || [])
            )
            .subscribe((result: any) => {
                if (result && result[0]) {
                    this.guid = result[0].guid;
                    this.response = result[0].findings;
                }
            });
        }
    }

    private getMessages(): void {
        this.subs.sink = this._internalMessageService.getMessage()
            // .pipe(
            //     filter(this._helper.notNullNotEmptyObject)
            // )
            .subscribe((messages: any) => {
                if (messages.name === 'interview') {
                    if (messages.data.message) {
                        this.selectedQuestion = messages.data.message;
                        this.selectQuestionNumber = messages.data.message.id;
                        this.clearAudioRecordedData();

                        if (this.interviewType === 'Written') {
                            //todo: get response text
                            this.getWrittenResponse();
                        }
                        else {
                            this.fetchMediaSource(this.projectId, this.selectedQuestion.control_guid);
                        }
                    }
                }

                if (messages.name === 'delegate') {
                    if (messages.data.action === 'update') {
                        this.selectedQuestion = messages.data.control;
                    }
                }
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    getCurrentUser(): void {
        this.subs.sink = this._userService.currentUser$
        .pipe(
            map((val: any) => val ?
                (Array.isArray(val) ? val[0] : val) : null)
        )
        .subscribe((x: CurrentUser) => {
            this.currentUser = x;
            this._changeDetectorRef.detectChanges();
        });
    }
}
