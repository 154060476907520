
<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
    <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-4 sm:py-4 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex items-center">
            <h1 class="text-2xl">{{project?.name}} > {{roleTitle}} > {{selectQuestionNumber}}</h1>
        </div>
        <span class="mr-6">
            <!-- <button mat-stroked-button color="primary" (click)="assessorView()" >
                <mat-icon class="icon-size-1 ml-1 hand" [svgIcon]="'heroicons_solid:search'"></mat-icon> Assessor View
            </button> -->
        </span>
    </div>

    <mat-drawer-container class="flex-auto h-full">

        <mat-drawer
            class="w-90 dark:bg-gray-900"
            [autoFocus]="false"
            [mode]="drawerMode"
            [opened]="drawerOpened"
            #matDrawer>
            <div class="flex flex-col items-start p-4 border-b">
                <button
                    class="flex-0" mat-flat-button [color]="'primary'" (click)="backToLastQuestion()">
                    <span class="ml-1">Skip to last answered question</span>
                </button>
                <ng-container *ngIf="isCampaign">
                    <button class="mt-2 text-center justify-center" mat-flat-button [color]="'warn'" (click)="generateReport()" >
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:document-report'"></mat-icon>
                        Generate Report
                    </button>
                </ng-container>
            </div>
            <div class="py-2 px-8">
                <ol>
                    <ng-container *ngFor="let question of questions; let last = last; trackBy: trackByFn">
                        <li
                            class="relative group py-6"
                            [class.current-step]="question.question_order_id === currentStep">
                            <ng-container *ngIf="!last">
                                <div
                                    class="absolute top-6 left-4 w-0.5 h-full -ml-px"
                                    [ngClass]="{'bg-primary': question.question_order_id < currentStep,
                                                'bg-gray-300 dark:bg-gray-600': question.question_order_id >= currentStep}"></div>
                            </ng-container>
                            <div
                                class="relative flex items-start cursor-pointer" (click)="goToStep(question.question_order_id)">
                                <div
                                    class="flex flex-0 items-center justify-center w-8 h-8 rounded-full ring-2 ring-inset bg-card dark:bg-default"
                                    [ngClass]="{'bg-primary dark:bg-primary text-on-primary group-hover:bg-primary-800 ring-transparent': question.order < currentStep,
                                                'ring-primary': question.question_order_id === currentStep,
                                                'ring-gray-300 dark:ring-gray-600 group-hover:ring-gray-400': question.question_order_id > currentStep}">

                                    <ng-container *ngIf="question.question_order_id < currentStep">
                                        <mat-icon
                                            class="icon-size-5 text-current"
                                            [svgIcon]="'heroicons_solid:check'"></mat-icon>
                                    </ng-container>
                                    <ng-container *ngIf="question.question_order_id === currentStep">
                                        <div class="text-md font-semibold text-primary dark:text-primary-500">{{question.question_order_id }}</div>
                                    </ng-container>
                                    <ng-container *ngIf="question.question_order_id > currentStep">
                                        <div class="text-md font-semibold text-hint group-hover:text-secondary">{{question.question_order_id }}</div>
                                    </ng-container>
                                </div>
                                <div class="ml-4">
                                    <div class="font-medium leading-4">Question
                                        <span class="text-primary-800 dark:text-primary-400">({{question.term}})</span>
                                    </div>
                                    <div class="mt-1.5 text-md leading-4 text-secondary">{{removeHtml(question.safeguard_summarized.substring(0,100))}}...</div>
                                    <div *ngIf="question.media_guid || question.control_id === control_id" class="mt-1 text-primary dark:text-primary-400">
                                        <mat-icon class="icon-size-5 text-hint " style="vertical-align: middle;" [svgIcon]="'heroicons_solid:microphone'"></mat-icon>
                                        Answered
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ol>
            </div>

        </mat-drawer>

        <mat-drawer-content class="flex flex-col overflow-hidden">

            <mat-progress-bar
                class="hidden lg:block flex-0 h-0.5 w-full"
                [value]="100 * (currentStep) / questions.length"></mat-progress-bar>

            <div class="flex-auto overflow-y-auto" cdkScrollable>
                <mat-tab-group class="fuse-mat-no-header" [animationDuration]="'200'" #courseSteps>
                    <ng-container *ngFor="let question of questions; trackBy: trackByFn">
                        <mat-tab>
                            <ng-template matTabContent>
                                <app-record-interview [question]="questions[currentStep - 1]" [questions]="questions"></app-record-interview>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>

        </mat-drawer-content>

    </mat-drawer-container>

</div>
